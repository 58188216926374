import React from 'react'
import { Col, Row } from 'react-bootstrap'

const ProductCard = ({app}) => {
  return (

    <Row className="gx-5 align-items-center" key={app.id}>
      <Col md={5} sm={12} className="order-sm-1 order-md-2 product-img">
        <img src={app.imgSrc} alt={app.title} className="img-fluid" />
      </Col>
      <Col md={7} sm={12} className="order-sm-2 order-md-1 product-details">
        <p className="product-title">{app.title}</p>
        <p className="product-description">{app.description}</p>
        <div className="small-break-line"></div>
      </Col>
    </Row>






    // <div className="row gx-5 align-items-center" key={app.id}>
    //   <div className="product-details col-md-7 col-sm-12">
    //     <p className="product-title">{app.title}</p>
    //     <p className="product-description">{app.description}</p>
    //     <div className="small-break-line"></div>
    //   </div>
    //   {/* <div className="col-2"></div> */}
    //   <div className="col-md-5 col-sm-12 product-img">
    //     <img src={app.imgSrc} alt={app.title} />
    //   </div>
    // </div>
  )
}

export default ProductCard