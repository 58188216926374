import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/navbar/navbar';
import { PublicProvider } from './context/public-context';
import Home from './components/pages/home/home';
import Footer from './components/footer/footer';

function App() {
  return (
    <PublicProvider>
      <BrowserRouter>
        <NavBar/>
        <Routes>
          <Route exact path="/" element={< Home/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </PublicProvider>
  );
}

export default App;
