import React from 'react'
import './footer.css';
import { Col } from 'react-bootstrap';
import workApps from '../../models/works_data';
export default function Footer() {
    const{email,phone}=workApps.socialMedia
  return (
    <footer>
        <div className="container">
            <div className="row align-items-center">
            <Col sm={0} md={4} className="d-none d-md-flex text-center mb-3 mb-md-0">
                © 2024 MSOL for development
            </Col>
            <Col sm={12} md={4} className="d-flex justify-content-center align-items-center mb-3 mb-md-0">
                <img src={`${process.env.PUBLIC_URL}/assets/images/footer.png`} alt="Footer" className="img-fluid" />
            </Col>
            <Col sm={12} md={4} className="d-flex flex-column justify-content-center align-items-center text-center mb-3 mb-md-0">
                <div className="contact-info">
                <div className="contact_us mb-1 mb-md-0">Contact us</div>
                    <div className="footer-icons d-flex justify-content-center">
                        <a href={`https://wa.me/${phone}`} target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/whatsapp.png`} alt="WhatsApp" className="mx-2" />
                        </a>
                        <a href={`mailto:${email}`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/email.png`} alt="Email" className="mx-2" />
                        </a>
                        <a href={`tel:${phone}`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/phone.png`} alt="Phone" className="mx-2" />
                        </a>
                    </div>
                </div>
            </Col>
            <Col sm={12} md={4} className="d-flex d-md-none justify-content-center align-items-center mb-3 mb-md-0">
                © 2024 MSOL for development
            </Col>
            </div>
        </div>
    </footer>
  )
}
