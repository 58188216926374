import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css';
function NavBar() {
  return (
    <>
      <Navbar  expand="lg" >
        <Container>
          <Navbar.Brand href="#home"><img src={`${process.env.PUBLIC_URL}/assets/images/nav-brand.png`}  alt="" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" /> 
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-lg-center">
              <Nav.Link href="#work">Work</Nav.Link>
              <Nav.Link href="#Services">Services</Nav.Link>
              <Nav.Link href="#talk">
                <div className="lets-talk">Let’s Talk</div>
              </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        
        </Container>
      </Navbar>

    </>
  );
}

export default NavBar;