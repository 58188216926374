import React from 'react'

export default function ServiceCard({service}) {
  return (
    <div className="service ">
              <p className='service-titel'>{service.title}</p>
              <p className='service-sub-titel'>{service.description}</p>
              <div className="small-break-line2"></div>
            </div>
  )
}
