import React, { useState ,useEffect } from 'react'
import { Alert, Button, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';


export default function ContactUs() {

    const [formData, setFormData] = useState({
        first_name: '',
        company_name: '',
        last_name: '',
        branches_num: '',
        email: '',
        store_type: '',
        phone: '',
        call_back: '',
        challenges: '',
        features: '',
        socialmedia: ''
    });
    const [errors, setErrors] = useState({})

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
   

    const HandleUpdateForm=(event)=>{
        const {name,value}=event.target;
        setFormData(prevState=>{
            return{
                ...prevState,
                [name]:value
            }
        })
    }

    const validateForm = () => {
        let errors = {};
    
        if (!formData.first_name) errors.first_name = 'First Name is required';
        if (!formData.company_name) errors.company_name = 'Company Name is required';
        if (!formData.last_name) errors.last_name = 'Last Name is required';
        if (!formData.branches_num) errors.branches_num = 'Number of branches is required';
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid';
        }
        if (!formData.store_type) errors.store_type = 'Store Type is required';
        if (!formData.phone) {
            errors.phone = 'Phone Number is required';
        } else if (!/^\d+$/.test(formData.phone)) {
            errors.phone = 'Phone Number is invalid';
        }
        if (!formData.call_back) errors.call_back = 'Call back time is required';
        if (!formData.challenges) errors.challenges = 'Challenges are required';
        if (!formData.features) errors.features = 'Features are required';
        if (!formData.socialmedia) errors.socialmedia = 'Social media links are required';
    
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    

    const sendEmail = (event) => {
        event.preventDefault();
        if (validateForm()) {
            console.log(formData);
            emailjs.sendForm('service_x0qwxfn', 'template_wr2az0g', event.target, 'laN648eiNge83CJw6')
            .then((result) => {
                console.log(result.text);
                setAlertMessage('Form Submitted successfully');
                setAlertVariant('success');
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 5000);
                setFormData({
                    first_name: '',
                    company_name: '',
                    last_name: '',
                    branches_num: '',
                    email: '',
                    store_type: '',
                    phone: '',
                    call_back: '',
                    challenges: '',
                    features: '',
                    socialmedia: ''
                });
            }, (error) => {
                console.log(error.text);
            });
        } else {
            setAlertMessage('Please fill in all required fields correctly.');
            setAlertVariant('danger');
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };
    
    useEffect(() => {
        const dateInput = document.querySelector('input[type="datetime-local"]');

        const handleBlur = () => {
            if (!dateInput.value) {
                dateInput.classList.remove('has-value');
            } else {
                dateInput.classList.add('has-value');
            }
        };

        dateInput.addEventListener('blur', handleBlur);

        // Set the class initially if there is already a value
        if (dateInput.value) {
            dateInput.classList.add('has-value');
        }

        return () => {
            dateInput.removeEventListener('blur', handleBlur);
        };
    }, []);


  return (
    <section className='mt-3' id='talk'>
    <p className='section-title'>Let’s talk</p>
    <div className="d-flex justify-content-center">
        <p className='section-sub-title text-center'>We've worked with businesses of all sizes, from big
          <br/>brands to new startups. Let's chat about your project
          <br/> and see how we can help.</p>
    </div>
    <div className="row  mt-2 d-flex justify-content-center">
        {/* ========================================= first_name & company_name ========================================= */}
        <Form className='col-md-8 col-sm-12 row gx-3 gy-4' onSubmit={sendEmail}>
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="text" placeholder="First Name" name='first_name' value={formData.first_name} onChange={HandleUpdateForm} />
                {errors.first_name && <Form.Text className="text-danger">{errors.first_name}</Form.Text>}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="text" placeholder="Company Name" name='company_name'  value={formData.company_name}  onChange={HandleUpdateForm} />
                {errors.company_name && <Form.Text className="text-danger">{errors.company_name}</Form.Text>}
            </Form.Group>
        {/* ========================================= last_name & branches_num ========================================= */}
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="text" placeholder="Last Name" name='last_name' value={formData.last_name} onChange={HandleUpdateForm} />
                {errors.last_name && <Form.Text className="text-danger">{errors.last_name}</Form.Text>}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="text" placeholder="Number of branches" name='branches_num' value={formData.branches_num} onChange={HandleUpdateForm} />
                {errors.branches_num && <Form.Text className="text-danger">{errors.branches_num}</Form.Text>}
            </Form.Group>
        {/* ========================================= email & store_type ========================================= */}
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="email" placeholder="Email Address" name='email' value={formData.email}  onChange={HandleUpdateForm} />
                {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12" >
            <Form.Select aria-label="Default select example" name='store_type' value={formData.store_type} onChange={HandleUpdateForm}>
                <option>Store type</option>
                <option value="Physical Store">Physical Store</option>
                <option value="Dark Store ( Warehouses for delivery)">Dark Store ( Warehouses for delivery)</option>
                <option value="Both">Both</option>
            </Form.Select>
            {errors.store_type && <Form.Text className="text-danger">{errors.store_type}</Form.Text>}
            </Form.Group>
        {/* ========================================= phone & date ========================================= */}
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control type="number" placeholder="Phone Number" name='phone' value={formData.phone} onChange={HandleUpdateForm} />
                {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12 input-placeholder-wrapper"  >
                <Form.Control    type="datetime-local"  
                    name='call_back' 
                    value={formData.call_back} 
                    onChange={HandleUpdateForm} 
                     />
                    <span className="custom-placeholder">call back time</span>

                {errors.call_back && <Form.Text className="text-danger">{errors.call_back}</Form.Text>}
            </Form.Group>
        {/* ========================================= Challenges & features & Social media links ========================================= */}
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control as="textarea" placeholder="What Challenges Are You Facing?"  value={formData.challenges} name='challenges' onChange={HandleUpdateForm} style={{ height: '100px' }}/>
                {errors.challenges && <Form.Text className="text-danger">{errors.challenges}</Form.Text>}
            </Form.Group>
            <Form.Group className="col-md-6 col-sm-12" >
                <Form.Control as="textarea" placeholder=" Main features you want ? " name='features'  value={formData.features} onChange={HandleUpdateForm} style={{ height: '100px' }}/>
                {errors.features && <Form.Text className="text-danger">{errors.features}</Form.Text>}
            </Form.Group>

            <Form.Group className="col-12" >
                <Form.Control as="textarea" placeholder=" Business Social media links ? " value={formData.socialmedia} name='socialmedia' onChange={HandleUpdateForm} style={{ height: '100px' }}/>
                {errors.socialmedia && <Form.Text className="text-danger">{errors.socialmedia}</Form.Text>}
            </Form.Group>
          
            <Button className='submit-button' type='submit' >Submit</Button>
        {showAlert && (
        <Alert variant={alertVariant} className='my-2' onClose={() => setShowAlert(false)} dismissible>
          {alertMessage}
        </Alert>
      )}
        </Form>
    </div>

  </section>
  )
}
