import React from 'react'
import './home.css';
import './home-res.css';
import workApps from '../../../models/works_data'; 
import ProductCard from '../../shared/product_card';
import ServiceCard from '../../shared/service_card';
import ContactUs from './contact_us';
export default function Home() {

  return (
    <div className='home'>
      <div className="container home-parent">

        <section className='top-secton '>
          <div className="section-details my-5">
            <p className='section-title'>Create. Launch. Thrive.</p>
            <p className='section-sub-title'>We build top-notch mobile experiences
              <br/> using smart strategies, sleek design, and
              <br/> advanced tech.</p>
              <a href='#work' className="see-work-container">
                <p>See Our Work</p>
                <img src={`${process.env.PUBLIC_URL}/assets/images/bottom_arrow.png`}  alt="" />
              </a>
          </div>
        </section>
        {/* ==================================== work section ================================================================= */}
        <section className=''id='work'>
          <p className='section-title'>Our Work</p>

          {/* ================================================================== */}
          <p className='work-title'>Grocery apps</p>
          {/* <div className="d-flex justify-content-center"> */}
            <div className="work-parent">
              <div className="container p-5">
                {workApps.groceryApps.map( app => (<ProductCard key={app.id} app={app}/>)) }
              </div>
            {/* </div> */}

          </div>
          {/* ================================================================== */}
          <p className='work-title'>AI Integrated features</p>
          <div className="work-parent">
            <div className="container p-5">
              {workApps.aiApps.map( app => (<ProductCard key={app.id} app={app}/>)) }
            </div>
          </div>
          {/* ================================================================ */}
          <p className='work-title'>Order Operation Apps</p>
          <div className="work-parent">
            <div className="container p-5">
              {workApps.orderApps.map( app => (<ProductCard key={app.id} app={app}/>)) }
            </div>
          </div>
          {/* ================================================================ */}
          <p className='work-title'>Retail optimization tools</p>
          <div className="work-parent">
            <div className="container p-5">
              {workApps.retailTools.map( app => (<ProductCard key={app.id} app={app}/>)) }
            </div>
          </div>
          {/* ================================================================ */}
        </section>
        {/* ==================================== service section ================================================================= */}
        <section className='mt-3' id='Services'>
          <p className='section-title'>Our Services:</p>
          <div className="d-flex justify-content-center">
              <p className='section-sub-title'>We handle everything from strategy and design
                <br/> to engineering and growth, providing
                <br/> comprehensive digital product solutions.</p>
          </div>
          <div className="row g-5 mt-1">
          
            {workApps.ourServices.map( service => (
              <div className="col-md-4 col-sm-12" key={service.id}>
              <ServiceCard service={service} />
            </div>
             ) )}
          </div>

        </section>
        {/* ==================================== let's talk ================================================================= */}
        <ContactUs />
      </div>

    </div>
  )
}
