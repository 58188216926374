const workApps = {
  groceryApps:[
    {
      id:1,
      title: "Oscar grand stores",
      description: "An all-in-one grocery shopping app with secure payments, order tracking, and personalized deals.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/oscar.png`
    },
    {
      id:2,
      title: "Super wings stores",
      description: "A versatile app for diverse grocery products, featuring easy navigation and exclusive deals.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/super.png`
    },
    {
      id:3,
      title: "Flamingo stores",
      description: "Offers a user-friendly shopping experience with a variety of products and special offers.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/flamingo.png`
    },
    {
      id:4,
      title: "Jira market",
      description: "Provides a broad selection of groceries with secure checkout and seamless shopping.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/Jira.png`
    },
  ],
  aiApps:[
    {
      id:5,
      title: "Oscar Chef",
      description: "Enhances customer service with AI-driven assistance, offering personalized cooking tips and support.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/oscar_chef.png`
    },
  ],
  orderApps:[
    {
      id:6,
      title: "Order Collector app",
      description: "Facilitates efficient order preparation with real-time updates and streamlined processes.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/order.png`
    },
    {
      id:7,
      title: "Driver app",
      description: "Optimizes delivery operations with route planning, tracking, and real-time communication.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/driver.png`
    },
  ],
  retailTools:[
    {
      id:8,
      title: "Lomatech",
      description: "A fleet management portal offering advanced solutions for your fleet management and optimization.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/lometic.png`
    },
    {
      id:9,
      title: "Shelfy",
      description: "Streamlines shelf management with real-time inventory updates and efficient organization.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/shelfy.png`
    },
    {
      id:10,
      title: "Warehouse management system  ( WMS )",
      description: "Offers comprehensive warehouse management with tracking, reporting, and optimization tools.",
      imgSrc: `${process.env.PUBLIC_URL}/assets/images/mws.png`
    },
  ],
  ourServices:[
    {
      id:11,
      title: "Custom Design",
      description: "We deliver smart, research-driven strategies and high-quality custom designs. Our UX/UI design process is thorough, addressing all user scenarios and edge cases. We also excel in creating standout brand designs.",
    },
    {
      id:12,
      title: "Scalable Solutions",
      description: "We develop robust iOS and Android apps designed for scalability and security. Our expertise extends to custom web platforms, leveraging the experience of industry veterans from top tech companies.",
    },
    {
      id:13,
      title: "Launch & Growth",
      description: "We ensure timely, budget-friendly product launches and continue with Agile product updates based on real user feedback. Launching is just the beginning of our ongoing support and growth strategies.",
    },
  ],
  socialMedia:{
    email:'retail@msol.dev',
    phone:'+201012290565',
  }

}
  
  export default workApps;